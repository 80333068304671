@charset "utf-8";

/*  -------------------------------------------------------------
    Eglide web site
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    Project:        Eglide
    Author:         Dereckson
    Dependencies:   foundation motion-ui
    Filename:       app.css
    Licence:        CC-BY 4.0, MIT
    -------------------------------------------------------------    */

/*  -------------------------------------------------------------
    Callout cards

    http://zurb.com/building-blocks/info-card-with-corner-lables
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -    */

$info: #89c4f4;

.callout-card {
  border: 1px solid #333;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  overflow: hidden;

  .card-label {
    border-color: transparent #333 transparent transparent;
    border-color: rgba(255,255,255,0) #333 rgba(255,255,255,0) rgba(255,255,255,0);
  }

  &.primary {
    border-color: $primary-color ;

    .card-label {
      border-color: transparent $primary-color transparent transparent;
      border-color: rgba(255,255,255,0) $primary-color rgba(255,255,255,0) rgba(255,255,255,0);
    }
  }
  &.secondary {
    border-color: $secondary-color;

    .card-label {
      border-color: transparent $secondary-color transparent transparent;
      border-color: rgba(255,255,255,0) $secondary-color rgba(255,255,255,0) rgba(255,255,255,0);
    }
  }
  &.info {
    border-color: $info;

    .card-label {
      border-color: transparent $info transparent transparent;
      border-color: rgba(255,255,255,0) $info rgba(255,255,255,0) rgba(255,255,255,0);
    }
  }
  &.alert {
    border-color: $alert-color;

    .card-label {
      border-color: transparent $alert-color transparent transparent;
      border-color: rgba(255,255,255,0) $alert-color rgba(255,255,255,0) rgba(255,255,255,0);
    }
  }
  &.success {
    border-color: $success-color;

    .card-label {
      border-color: transparent $success-color transparent transparent;
      border-color: rgba(255,255,255,0) $success-color rgba(255,255,255,0) rgba(255,255,255,0);
    }
  }
  &.warning {
    border-color: $warning-color;

    .card-label {
      border-color: transparent $warning-color transparent transparent;
      border-color: rgba(255,255,255,0) $warning-color rgba(255,255,255,0) rgba(255,255,255,0);
    }
  }

  .card-label {
    border-style: solid;
    border-width: 0 70px 40px 0;
    float:right;
    height: 0px;
    width: 0px;
    transform:rotate(360deg);
  }

  .callout-card-content{
    padding: 0.5rem 1.5rem 0.875rem;
  }

  &.radius {
    border-radius: 0.6rem;
  }

  .label-text {
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    position: relative;
    right: -40px;
    top: 2px;
    white-space: nowrap;
    transform: rotate(30deg);
  }
}
