@charset "utf-8";

/*  -------------------------------------------------------------
    Eglide web site
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    Project:        Eglide
    Author:         Dereckson
    Dependencies:   foundation
    Filename:       app.css
    Licence:        CC-BY 4.0, MIT
    -------------------------------------------------------------    */

.news {
  margin-top: 1rem;
  margin-bottom: 2rem;

  h3 {
    font-family: $body-font-family;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 0.5em;

    i {
      margin-right: 0.5rem;
    }
  }
}
