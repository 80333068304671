@charset "utf-8";

/*  -------------------------------------------------------------
    Eglide web site
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    Project:        Eglide
    Dependencies:   foundation motion-ui
    Filename:       app.css
    Licence:        CC-BY 4.0, MIT
    -------------------------------------------------------------    */

// http://zurb.com/building-blocks/header-and-subnav

.header {
  text-align: center;
  background: $header-bg;
  background-size: cover;
  position: relative;
  overflow: visible;
  padding-top: $header-height;
  padding-bottom: $header-height + ($subnav-link-padding * 2 + $subnav-font-size - 0.15rem);

  .headline {
    color: #ffe;
  }
}

.header-subnav {
  float: none;
  position: absolute;
  text-align: center;
  margin: 0 auto;
  bottom: 0;
  width: 100%;

  li {
    float: none;
    display: inline-block;

    a {
      padding: $subnav-link-padding 1rem;
      font-size: $subnav-font-size;
      color: #ffe;
      text-transform: uppercase;
      display: block;
      font-weight: bold;
      letter-spacing: 1px;
      transition: $transition;

      &.is-active {
        background: rgba(250,250,250,$subnav-link-opacity);
      }

      &:hover {
        background: rgba(250,250,250,$subnav-link-opacity);
        transition: $transition;
      }
    }
  }

  i {
    margin-right: 0.5em;
  }
}

// Rules applying to page header and content header.
header {
  margin-bottom: $header-height;
}
