@charset "utf-8";

/*  -------------------------------------------------------------
    Eglide web site
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    Project:        Eglide
    Author:         Dereckson
    Dependencies:   foundation
    Filename:       app.css
    Licence:        CC-BY 4.0, MIT
    -------------------------------------------------------------    */

// http://zurb.com/building-blocks/header-card-panel

.panel {
  margin: 3rem auto;
  border: 2px solid #444;
  background: yellow;

  p {
    font-size: 1rem;
    padding: 1rem 2rem 2rem 2rem;
    margin-top: -1.25rem;

    span {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 75%;
      font-weight: bold;
    }
  }

  .sign {
    background: #FF3D2E;
    width: 150px;
    height: 2.4rem;
    border: 2px solid #444;
    position: relative;
    top: -2.5rem;
    margin: auto;

    h1 {
      text-transform: uppercase;
      font: bolder 0.875rem;
      letter-spacing: 3px;
      color: $black;
      font-size: 70%;
      text-align: center;
      line-height: 2rem;
    }
  }
}

