@charset "utf-8";

/*  -------------------------------------------------------------
    Eglide web site
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    Project:        Eglide
    Author:         Dereckson
    Dependencies:   foundation motion-ui
    Filename:       app.css
    Licence:        CC-BY 4.0, MIT
    -------------------------------------------------------------    */

/*  -------------------------------------------------------------
    Block grid
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -    */

.blockgrid {
  padding: 1em 0;
}

.blockgrid img {
  margin: 1rem 0;
}

.black-margin {
  border: 8px solid black;
}

.white {
  background-color: white;
  color: black;
  font-weight: 700;
}
