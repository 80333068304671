@charset "utf-8";

/*  -------------------------------------------------------------
    Eglide web site
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    Project:        Eglide
    Dependencies:   foundation motion-ui
    Filename:       app.css
    Licence:        CC-BY 4.0, MIT
    -------------------------------------------------------------    */

.footer {
  background-color: #292c2f;
  text-align: center;
  font-size: 1.2rem;
  padding: 4rem;

  margin-top: 10vh;

  .inline-list a {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-color: #33383b;
    border-radius: 2px;
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    line-height: 35px;
    margin: 0 3px 0 0;
  }

  .contact {
    list-style-type: none;
    margin: 0;
    text-align: left;
    color: #fff;

    li {
      margin-bottom: 2rem;

      p {
        padding-left: 5rem;
      }
    }

    i {
      position: absolute;
      background-color: #33383b;
      color: #fff;
      font-size: 2rem;
      border-radius: 50%;
      line-height: 1;
      margin: 0 0 0 -4rem;
      vertical-align: middle;
      padding: .25em .45em;
    }
  }

  .copywrite {
    color: #8f9296;
    font-size: 0.875rem;
    margin: 0 0 1rem 0;
  }

  .about {
    color: #92999f;
    font-size: 0.875rem;
    margin: 0 0 1rem 0;
  }

  .footer-links a {
    list-style: none;
    font-weight: normal;
    color: #fff;
    padding: 3rem 0 2rem;
    margin: 0;
    font-size: 0.875rem;

    &::after {
      content: "•";
      padding: 0 0.2rem 0 0.4rem;
    }

    &:last-child::after {
      content: "";
      padding: 0 0.4rem 0 0.8rem;
    }
  }

  @media only screen and (min-width: 40.063em) {

    p {
      text-align: left;
    }

    .social {
      text-align: left;
      margin: 0;
    }

    .contact {
      text-align: left;
      > i {
        margin-right: 1rem;
      }
    }

  }
}

.logo {
  color: #fff;
  font-size: 1.5rem;

  i {
    margin-right: 0.5rem;
  }
}
