@charset "utf-8";

/*  -------------------------------------------------------------
    Eglide web site
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    Project:        Eglide
    Author:         Dereckson
    Dependencies:   foundation motion-ui
    Filename:       app.css
    Licence:        CC-BY 4.0, MIT
    -------------------------------------------------------------    */

@import 'settings';

/*  -------------------------------------------------------------
    Table of contents
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

    :: Dependencies
    :: Our design

*/

/*  -------------------------------------------------------------
    Dependencies

      Foundation
      Motion UI
      Foundation Icon Fonts 3
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -    */

@import 'foundation';
@import 'motion-ui';
@import '_foundation-icons';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
/*
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

@include motion-ui-transitions;
@include motion-ui-animations;
*/

/*  -------------------------------------------------------------
    Our design
    - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -    */

@import 'components/layout';
@import 'components/footer';
@import 'components/header';
@import 'components/blockgrid';
@import 'components/panel';
@import 'components/cards';
@import 'components/status';
@import 'components/news';
